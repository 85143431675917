<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Vendor">
          <div class="flex flex-wrap justify-end w-full sm:w-auto mb-4">
            <!-- <div class="w-full sm:w-auto">
              <vx-tooltip text="Sync Vendor From Tally" position="left">
                <vs-button
                  class="px-2 py-3 mr-0 sm:mr-2 mb-3 w-full sm:w-auto"
                  @click.native="syncVendor()"
                >
                  <span class="d-inline-flex pr-5 text-white"
                    >Sync Vendor From Tally</span
                  >
                </vs-button>
              </vx-tooltip>
            </div> -->

            <div class="w-full sm:w-auto">
              <vx-tooltip text="Template Excel" position="left">
                <vs-button
                  @click.native="
                    onClickSampleDownload(
                      excelurl + `/v2/contractVendor/excel/sample`
                    )
                  "
                  type="border"
                  class=" px-2 py-3 mb-3 w-full sm:w-auto"
                >
                  <img
                    class="d-inline-flex mr-2"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                  />Sample Excel
                </vs-button>
              </vx-tooltip>
            </div>

            <div class="w-full sm:w-auto sm:ml-2">
              <vx-tooltip text="Import Excel" position="left">
                <label for="import-file" class="w-full text-center">
                  <input
                    ref="uploadExcelFile"
                    @change="uploadExcel($event)"
                    type="file"
                    name="import-file"
                    id="import-file"
                    class="d-none"
                  />
                  <div
                    class="w-full vs-component vs-button px-4 vs-button-primary vs-button-filled float-right"
                  >
                    <span class="d-inline-flex pr-5 text-white">Import</span>
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/import-excel.svg"
                      height="20"
                      width="20"
                    />
                  </div>
                </label>
              </vx-tooltip>
            </div>
          </div>

          <form>
            <div class="row">
              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Name *</h6>
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  name="Vendor Name"
                  v-model="form.vendor_name"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                  >
                    {{ errors.first("Vendor Name") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>PAN Number * (eg.ABCDE1234A)</h6>
                <vs-input
                  v-validate="'required|alpha_num|min:10|max:10'"
                  name="Vendor Pan"
                  v-model="form.vendor_pan"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Pan')"
                  >
                    {{ errors.first("Vendor Pan") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Aadhar *</h6>
                <vs-input
                  v-validate="'required|numeric|max:12|min:12'"
                  name="Vendor Aadhar"
                  type="number"
                  v-model="form.vendor_aadhar"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Aadhar')"
                  >
                    {{ errors.first("Vendor Aadhar") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>GST Number*</h6>
                <vs-input
                  v-validate="'required|max:15|min:15'"
                  name="vendor GST"
                  v-model="form.vendor_gst"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('vendor GST')"
                  >
                    {{ errors.first("vendor GST") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Pincode *</h6>
                <vs-input
                  type="text"
                  v-validate="'required|max:10'"
                  name="Vendor Pincode"
                  v-model="form.vendor_pincode"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Pincode')"
                  >
                    {{ errors.first("Vendor Pincode") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Mob Number *</h6>
                <vs-input
                  type="number"
                  v-validate="'required|max:16|min:8'"
                  name="Vendor Number"
                  v-model="form.vendor_number"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Number')"
                  >
                    {{ errors.first("Vendor Number") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Type of Vendor *</h6>
                <vs-input
                  v-validate="'required'"
                  name="Type of Vendor"
                  v-model="form.type_of_vendor"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of Vendor')"
                  >
                    {{ errors.first("Type of Vendor") }}
                  </span>
                </p>
              </div>
              <!-- <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Vendor From *</h6>
                <multiselect
                  label="alt_org_name"
                  class="w-full"
                  value="default_alt_org_id"
                  v-model="form.default_alt_org_id"
                  name="Name"
                  :options="alternateOrg"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                  autocomplete="off"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.alt_org_name
                  }}</template>
                </multiselect>
              </div> -->

              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Services *</h6>
                <vs-input
                  v-validate="'required'"
                  name="Vendor Services"
                  v-model="form.vendor_services"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Services')"
                  >
                    {{ errors.first("Vendor Services") }}
                  </span>
                </p>
              </div>

              <div class="col-12 col-md-1 mb-2">
                <h6>Status</h6>
                <vs-switch v-model="switch1" />
              </div>

              <div class="col-12 col-md-1 mb-2">
                <h6>Contract</h6>
                <vs-switch v-model="form.is_contract_vendor" />
              </div>

              <div class="col-12 col-md-2 mb-2">
                <h6>Impressed User</h6>
                <vs-switch v-model="form.is_impressed_user" />
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4  col-sm-4 mb-2">
                <h6>Address</h6>
                <vs-textarea
                  v-model="form.vendor_address"
                  class="mb-0"
                  rows="4"
                />
              </div>
            </div>
          </form>

          <div class="vx-row">
            <div class="vx-col mx-auto">
              <vs-button
                :disabled="submitStatus"
                type="filled"
                @click.prevent="submitForm"
                class="mr-3 mb-3 w-full sm:w-auto"
              >
                Submit
              </vs-button>

              <vs-button
                color="warning"
                type="border"
                class="mb-3 w-full sm:w-auto"
                @click.prevent="clearForm"
              >
                Reset
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import ContractService from "@/services/ContractService.js";
import axios from "axios";
import projectService from "@/services/projectService.js";
import { BASEURL } from "@/config/index.js";
export default {
  components: {},
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: true,
      switch1: true,
      form: {
        vendor_name: "",
        vendor_pan: "",
        vendor_aadhar: "",
        vendor_gst: "",
        vendor_address: "",
        vendor_pincode: "",
        vendor_number: "",
        type_of_vendor: "",
        vendor_services: "",
        default_alt_org_id: "",
        vendor_isactive: 1,
        is_impressed_user: false,
        is_contract_vendor: false,
      },
      alternateOrg: [],
      organization_id: "",
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
            Object.keys(newVal).filter(
              (x, index) =>
                newVal[x] &&
                newVal[x] !== undefined &&
                String(newVal[x]).trim().length > 0
            ).length ||
          this.form.vendor_name != ""
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    syncVendor() {
      let that = this;
      this.$vs.loading();
      axios
        .get(
          `${BASEURL}/v2/tally/syncVendor?organization_id=${
            that.organization_id
          }`
        )
        .then((response) => {
          const { data } = response;
          this.$vs.notify({
            title: "Updated!",
            text: data.Message || "Updated",
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: data.Status ? "success" : "warning",
          });
          this.$vs.loading.close();
          // this.syncVendorTv();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    syncVendorTv() {
      let that = this;
      this.$vs.loading();
      axios
        .get(
          `${BASEURL}/v2/tally/syncTvVendor?organization_id=${
            that.organization_id
          }`
        )
        .then((response) => {
          const { data } = response;
          this.$vs.notify({
            title: "Updated!",
            text: data.Message || "Updated",
            time: 2000,
            iconPack: "feather",
            icon: "check_box",
            color: data.Status ? "success" : "warning",
          });
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
        });
    },
    panValid() {
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      console.log(this.form.vendor_pan, regpan.test(this.form.vendor_pan));
      if (regpan.test(this.form.vendor_pan)) {
        return true;
      } else {
        return false;
      }
    },

    uploadExcel: function(event) {
      this.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(
          this.excelurl +
            `/v2/contractVendor/import/excel?organization_id=${
              this.organization_id
            }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("access_token"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (!data.Status || data.Status == "false") {
            this.$vs.notify({
              title: "Error!",
              text: data.Message || "Import Failed",
              time: 2000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            this.$vs.notify({
              title: "Imported!",
              text: data.Message || "Imported Successfully",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("conrefreshTableVendor", data);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    uploadFromTally: function(event) {
      this.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(
          this.excelurl +
            `/v2/contractVendor/importfromtally?organization_id=${
              this.organization_id
            }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (!data.Status) {
            this.$vs.notify({
              title: "Error!",
              text: data.error.message || "Error",
              time: 35000,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          } else {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            eventBus.$emit("conrefreshTableVendor", data);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },

    submitForm() {
      if (this.form.vendor_pan) {
        if (!this.panValid()) {
          this.$vs.notify({
            title: "Error!",
            text: "PAN Number is not Valid",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          return false;
        }
      }

      if (
        this.form.vendor_aadhar != "" &&
        this.form.vendor_aadhar.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "Aadhar Number is not Valid",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }

      if (
        this.form.vendor_gst != "" &&
        this.form.vendor_gst.toString().length < 12
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "GST Number is not Valid",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }

      if (this.form.vendor_name != "") {
        this.$vs.loading();
        this.submitStatus = false;
        let payload = {
          vendor_id: "",
          ...this.form,
          vendor_isactive: this.switch1 ? "1" : "2",
          is_impressed_user: this.form.is_impressed_user === true ? 1 : 0,
          is_contract_vendor: this.form.is_contract_vendor === true ? 1 : 0,
          is_tv: 0,
        };

        ContractService.addContractVendorNew(payload)
          .then((response) => {
            this.submitStatus = false;
            const { data } = response;
            this.$vs.loading.close();
            if (data.Status == true || data.Status == "true") {
              this.$vs.notify({
                title: "Created!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.clearForm();
              setTimeout(() => {
                eventBus.$emit("conrefreshTableVendor", data);
              }, 1);
            } else {
              this.$vs.notify({
                title: "Error!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch((error) => {
            this.submitStatus = true;
          });
      }
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_isactive/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
    vendorFromList: function() {
      this.alternateOrg = [];
      projectService
        .getAllProjects(null, 1)
        .then((response) => {
          const { data } = response;
          if (data.Status || data.Status == "true") {
            if (data.alternateOrgNew.length > 0) {
              this.alternateOrg = data.alternateOrgNew;
            } else {
              this.alternateOrg = [];
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  beforeMount() {
    this.vendorFromList();
  },
  mounted() {
    this.organization_id = localStorage.getItem("OrganizationID");
  },
};
</script>
