<template>
  <div class="h-full flex items-center justify-center">
    <vs-chip :color="value == 1 ? 'success' : 'danger'"> </vs-chip>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      value: 0,
    };
  },
  beforeMount() {
    this.value = this.params.value;
  },
});
</script>

<style></style>
