import { render, staticRenderFns } from "./vendorArchive.vue?vue&type=template&id=31d982b3&"
import script from "./vendorArchive.vue?vue&type=script&lang=js&"
export * from "./vendorArchive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports