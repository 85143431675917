<template>
  <div>
    <vs-checkbox
      class="table-status-icon"
      v-model="checkBox1"
      @click="selectVendors"
      style="padding-top: 12px"
      :disabled="isdisbaled"
    ></vs-checkbox>
  </div>
</template>

<script>
import Vue from "vue";
//
import _ from "lodash";

export default Vue.extend({
  data() {
    return {
      checkBox1: false,
      newData: [],
      isdisbaled: false,
    };
  },
  watch: {},

  beforeMount() {
    this.newData = this.params.data;

    eventBus.$on("syncCheckboxes", (e) => {
      // console.log(e, this.params.data.vendor_id);
      if (this.params.data.vendor_isactive == 2) {
        let bol = false;
        e.map((item, index) => {
          if (this.params.data.vendor_id == item.vendor_id) {
            bol = true;
          }
          this.checkBox1 = bol;
        });
      }
    });
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodale" + this.params.data.vendor_id ||
        "popmodalr" + this.params.data.vendor_id
    );
    // _id.setAttribute("style", "display: none;");
  },
  computed: {},
  methods: {
    selectVendors() {
      // console.log("fdsfdsf", this.checkBox1);
      setTimeout(() => {
        eventBus.$emit("selectedVendor", this.params.data);
      }, 1);
    },
  },
  mounted() {
    console.log(this.params.data.vendor_isactive, "tallySync_status");
    if (this.params.data.vendor_isactive == 2) {
      this.isdisbaled = false;
    } else {
      this.isdisbaled = true;
    }
  },
});
</script>
<style>
.po-popup .vs-popup--content {
  height: 100%;
}
</style>
