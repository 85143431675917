<template>
  <div>
    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Map Unsynced Vendor With Synced Vendor"
      :active.sync="popupActivo2"
    >
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-12">
              Tally Vendor List:
              <div class="mb-4">
                <multiselect
                  label="vendor_name"
                  class="w-full"
                  track-by="vendor_name"
                  v-model="selectMapvendorsb"
                  name="Synced Vendors"
                  :options="selectMapvendorsblist"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Synced Vendors')"
                  >
                    {{ errors.first("Synced Vendors") }}
                  </span>
                </p>
              </div>
            </div>

            <div class="col-12 mb-4">
              Unsynced Vendor List:
              <multiselect
                label="vendor_name"
                class="w-full"
                track-by="vendor_name"
                v-model="unsyncVendor"
                name="Unsynced Vendors"
                :options="unsyncVendorlist"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove"
                :multiple="true"
                @input="removeUnsyncvendor"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Unsynced Vendors')"
                >
                  {{ errors.first("Unsynced Vendors") }}
                </span>
              </p>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col mx-auto">
              <vs-button
                type="filled"
                class="mr-3"
                @click.prevent="submitVendorMap"
              >
                Submit
              </vs-button>
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Vendor List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right w-full sm:w-auto"
            >
              <vs-input
                class="mb-4 mr-0 sm:mr-4 w-full sm:w-auto"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>

            <!-- <vs-button
              class="px-4 mr-0 sm:mr-4 mb-4 w-full sm:w-auto"
              :disabled="unsyncVendor.length <= 0"
              @click.native="showMapPopup()"
            >
              Map Vendor
            </vs-button> -->

            <vx-tooltip text="Export Excel" class="w-full sm:w-auto mb-4">
              <a
                :href="
                  excelurl +
                    `/v2/contractVendor/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive != 'archive'"
              >
                <vs-button
                  v-if="
                    this.permissionPageAccessArry.includes(
                      PermissionVariables.UserExport
                    ) || loginUserRole == 'Admin'
                  "
                  class="px-4 w-full sm:w-auto"
                >
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>

            <vx-tooltip
              text="Export Archive Excel"
              class="w-full sm:w-auto mb-4"
            >
              <a
                :href="
                  excelurl +
                    `/v2/contractVendor/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive == 'archive'"
              >
                <vs-button class="px-4 w-full sm:w-auto">
                  <span class="d-inline-flex pr-5 text-white">Export</span>

                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="vendorsList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        />
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ContractService from "@/services/ContractService.js";

import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import vendorStatus from "./vendorStatus.vue";
import editVendor from "./editVendor.vue";
import IsContractVendor from "./IsContractVendor.vue";
import vendorArchive from "./vendorArchive.vue";
import tallySyncStatus from "./tallySyncStatus.vue";
import multiSelectVendor from "./multiSelectVendor.vue";

import PermissionVariables from "@/services/UserManagementVaraibles.js";
import Multiselect from "vue-multiselect";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Multiselect,
  },
  props: ["listType"],
  data() {
    return {
      unsyncVendorlist: [],
      unsyncVendor: [],
      selectMapvendorsblist: [],
      selectMapvendorsb: null,
      popupActivo2: false,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      selectedTallyOption: "All",
      excelurl: BASEURL,
      archive: this.listType,
      vendorsList: [],
      totalPages: 0,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      organization_id: "",
      isTally: "",
    };
  },
  beforeMount() {
    eventBus.$on("conrefreshTableVendor", (e) => {
      this.getWorkTypeList(this.currentPage);
    });
    this.organization_id = localStorage.getItem("OrganizationID");
    this.isTally = localStorage.getItem("isTally");
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        filter: false,
        width: 100,
        suppressSizeToFit: true,
        cellRendererFramework: multiSelectVendor,
      },
      {
        headerName: "Vendor Name",
        field: "vendor_name",
        filter: false,
        width: 300,
        suppressSizeToFit: true,
      },
      {
        headerName: "PAN",
        field: "vendor_pan",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Aadhar",
        field: "vendor_aadhar",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "GST",
        field: "vendor_gst",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Contract",
        field: "is_contract_vendor",
        suppressSizeToFit: true,
        cellRendererFramework: IsContractVendor,
      },
      {
        headerName: "Type of Vendor",
        field: "type_of_vendor",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 150,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.VendorEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: editVendor,
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        hide:
          !this.permissionPageAccessArry.includes(
            PermissionVariables.VendorArchive
          ) && this.loginUserRole != "Admin"
            ? true
            : false,
        sortable: false,
        width: this.archive ? 160 : 150,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: vendorArchive,
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getWorkTypeList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    removeUnsyncvendor() {
      setTimeout(() => {
        eventBus.$emit("syncCheckboxes", this.unsyncVendor);
      }, 20);
    },

    showMapPopup() {
      this.popupActivo2 = true;
      let filter = {
        listType: this.listType,
      };
      ContractService.getContractVendorNew(filter, null, "dashboard")
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            this.unsyncVendorlist = [];
            this.selectMapvendorsblist = [];
            for (var i = 0; i < data.data.length; i++) {
              if (data.data[i].vendor_isactive == 1) {
                this.selectMapvendorsblist.push(data.data[i]);
              } else {
                this.unsyncVendorlist.push(data.data[i]);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData
        .map((node) => node.make + " " + node.model)
        .join(", ");
    },

    onSelectTallySyncOption: function(syncType) {
      this.selectedTallyOption = syncType;
      let payload = {
        limit: this.paginationPageSize,
        type: 0,
        page_no: this.currentPage,
        tallySync: syncType,
      };
      ContractService.getContractVendorNew(payload)
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.vendorsList = data.data;
            this.totalPages = data.pagination.last_page;
            this.autoSizeAll();
          } else {
            this.$vs.notify({
              title: "No Data found for the Request",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }

          if (data.hasOwnProperty("tally_isActive")) {
            this.showTally = data.tally_isActive;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateSearchQuery(val) {
      SearchService.getAllSerchResultV2(
        val,
        "contractVendor",
        null,
        this.listType === "archive"
      )
        .then((response) => {
          const { data } = response;
          if (data.Status && data.data && data.data.length > 0) {
            this.vendorsList = data.data;
          } else {
            this.vendorsList = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getWorkTypeList: function(currentPage) {
      this.vendorsList = [];
      let filter = {
        page: currentPage,
        is_old: 1,
        listType: this.listType,
      };
      if (this.archive == "archive") {
        if (this.vendorsList.length == 1) {
          this.vendorsList = [];
        }
        filter["archive"] = true;
      }
      ContractService.getContractVendorNew(filter, null, "dashboard")
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            this.vendorsList = data.data;
            this.totalPages = data.pagination.last_page;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitVendorMap() {
      let unsyncedVendorsString = this.unsyncVendor
        .map((e) => e.vendor_id)
        .join(", ");
      let syncedVendor = this.selectMapvendorsb.vendor_id;

      let payload = {
        unsyncedIds: unsyncedVendorsString,
        syncedId: syncedVendor,
      };
      ContractService.vendorMap(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Success!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActivo2 = false;
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.popupActivo2 = false;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Something went wrong!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          this.popupActivo2 = false;
        });

      this.unsyncVendorlist = [];
      this.unsyncVendor = [];
      this.selectMapvendorsblist = [];
      this.selectMapvendorsb = null;
      this.popupActivo2 = false;
      this.getWorkTypeList(this.currentPage);
    },
  },
  mounted() {
    eventBus.$on("selectedVendor", (e) => {
      let arrIndex = this.unsyncVendor.indexOf(e);
      if (arrIndex >= 0) {
        this.unsyncVendor.splice(arrIndex, 1);
      } else {
        this.unsyncVendor.push(e);
      }
      this.$forceUpdate();
    });

    this.getWorkTypeList(this.currentPage);
    this.gridApi = this.gridOptions.api;
  },
};
</script>
