<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/dashboard/analytics">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div
        v-if="
          this.permissionPageAccessArry.includes(
            PermissionVariables.VendorAdd
          ) || loginUserRole == 'Admin'
        "
        class="vx-col w-full mb-base"
      >
        <vendorForm />
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab label="Vendor List" icon-pack="feather" icon="icon-box">
            <div class="tab-text">
              <div>
                <vendorList />
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
            <div>
              <vendorList listType="archive" />
            </div>
          </vs-tab>
          <vs-tab
            label="Impressed User List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <impressed-user-list />
            </div>
          </vs-tab>
          <!-- <vs-tab
            label="Television Vendor List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <vendorList listType="tv" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            label="Television Impressed User List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div>
              <impressed-user-list listType="tvImpressed" />
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import vendorForm from "@/views/apps/contractvendor/vendorForm.vue";
import vendorList from "@/views/apps/contractvendor/vendorList.vue";
import impressedUserList from "@/views/apps/contractvendor/impressedUserList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    vendorForm,
    vendorList,
    impressedUserList,
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
    };
  },
};
</script>
